import create from "zustand";
import { calc } from "../../utils/calcGPA";

// eslint-disable-next-line
const store = (set: any, get: any) => ({
  data: [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDataSource: async (data: any[]) => {
    set({ data });
  },
  getGPA: async () => {
    const data = await get().data;

    const { gpa, totalGrade, totalHours } = calc(data);

    return {
      totalGrade,
      totalHours: Number(totalHours),
      gpa: Number(gpa).toFixed(2) || 0,
    };
  },
});

const useStore = create(store);

export { useStore };
