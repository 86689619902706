import React, { useEffect, useState } from "react";

import { TStore, useStore } from "../store";
import { Button } from "antd";
import {
  Buttons,
  TitleCard,
  Title,
  Content,
  Result,
  Paragraph,
  Section,
  Underline,
} from "./style";
import { Card } from "antd";
import { TResume } from "./types";
import {
  CalendarOutlined,
  FormOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

const Resume = ({ onChangePage, infos }: TResume) => {
  const { getGPA, data }: TStore = useStore((state: TStore) => state);

  const [gpa, setGPA] = useState({
    totalHours: 0,
    gpa: 0,
  });

  const calc = async () => {
    setGPA(await getGPA());
  };

  useEffect(() => {
    calc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div>
        <Title>Resultado</Title>

        <Content>
          <Card>
            <TitleCard>GPA:</TitleCard>
            <Result> {gpa.gpa}</Result>
          </Card>

          <Card>
            <TitleCard>Total de créditos:</TitleCard>
            <Result> {gpa.totalHours}</Result>
          </Card>
        </Content>
      </div>
      <Buttons>
        <Button
          onClick={() =>
            window.open(
              "https://app.pipefy.com/public/form/XyiNiEl2",
              "_blank"
            )
          }
        >
          <FormOutlined /> Teste suas chances
        </Button>
        <Button
          onClick={() =>
            window.open(
              "https://calendly.com/atendimentouc/atendimento-site",
              "_blank"
            )
          }
        >
          <CalendarOutlined /> Agende uma consulta
        </Button>
      </Buttons>

      <Buttons>
        <Button onClick={() => onChangePage()}>
          <ArrowLeftOutlined /> Voltar
        </Button>
      </Buttons>

      {infos && (
        <Section>
          <Title> O que é e para que serve o GPA?</Title>

          <Paragraph>
            O GPA (Grade Point Average) é uma medida utilizada em sistemas
            educacionais para calcular a média de desempenho acadêmico de um
            estudante. O GPA é baseado em um sistema de pontuação, no qual cada
            nota recebida pelo estudante é convertida em um valor numérico,
            geralmente em uma escala de 0 a 4.
          </Paragraph>

          <Paragraph>
            É um dos critérios avaliados durante o processo de análise e seleção
            de estudantes nas universidades do exterior. O GPA é usado para
            comparar e avaliar os candidatos entre si de forma “objetiva”.
          </Paragraph>

          <Paragraph>
            Muitas universidades já avaliam os estudantes com base nas suas
            notas no sistema de ensino do seu país, então não é necessário
            converter a nota 0 a 10, 0 a 20 ou 1 a 7 (IB) para escala de 0 a 4
            do GPA. Inclusive, hoje são bem poucas universidades que exigem
            avaliações de currículo por empresas terceirizadas. (WES, ECE)
          </Paragraph>

          <Paragraph>
            O termo GPA já não é mais usado por nós da Daqui pra Fora e nossos
            alunos. Usamos o termo “média escolar”. Nós calculamos a média
            escolar de nossos estudantes na escala das escolas deles, pois as
            universidades do exterior olham as notas dos estudantes dentro do
            contexto de cada estudante/escola, ou seja, no formato que a escola
            do estudante usa.
          </Paragraph>

          <Paragraph>
            <Underline>IMPORTANTE</Underline> – Cada universidade usa o seu
            sistema de notas e graus para medir o desempenho acadêmico dos
            estudantes. Por conta disso, cada universidade faz seu próprio
            cálculo do GPA (média escolar). Isso resulta em cálculos de GPA que
            podem variar em média de 0,15 para cima ou 0,15 para baixo
            dependendo da universidade.
          </Paragraph>
        </Section>
      )}
    </>
  );
};

export { Resume };
