import { styled } from "styled-components";
import { mainTheme } from "../../theme";


export const HistoryContent = styled.div`

  th {
    font-family: 'Effra Trial Bold' !important;
    color: ${mainTheme.colors.primary.main} !important;
  }

  td {
    font-family: 'Effra' !important; 
  }


`;


export const Calc = styled.div`
  button {
    border: 1px solid ${mainTheme.colors.primary.main};
    background-color: ${mainTheme.colors.primary.main};
    color: #fff;
    font-family: 'Effra Trial Bold' !important;
    padding: 9px 18px 9px 16px;
    font-size: 16px;
    height: 41px
  }
`;


export const Flexbox = styled.div`
  margin-bottom: 32px;
`;

export const TitleCard = styled.h3`
  text-align: center;
  margin: auto auto 16px auto;
  width: 100%;
  font-family: 'Effra Trial Bold' !important;
  color: ${mainTheme.colors.primary.main};
  font-size: ${({ theme }) => theme.size.font.large};
`;

export const Title = styled.h1`
  text-align: center;
  margin: 16px auto 16px auto;
  color: ${mainTheme.colors.primary.main};
  font-family: 'Effra Trial Bold' !important;
`;


export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.size.font.medium};
  font-family: 'Effra' !important;
`;



