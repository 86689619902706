import React from "react";
import { Route, Routes as RoutesComponent } from "react-router-dom";

import { Home } from "../pages/Home";
import { DGE } from "../pages/DGE";

const Routes = () => {
  return (
    <RoutesComponent>
       <Route index element={<Home />} />
       <Route path="/dge" element={<DGE />} />
    </RoutesComponent>
  );
};

export { Routes };
