import React, { Component } from "react";
import MutationObserver from "mutation-observer";

class FormUser extends Component {
  componentDidMount() {
    const form = new window.RDStationForms(
      "calculo-do-gpa-d0221780070f5a957267",
      "UA-97631228-1"
    );

    const formNode = document.querySelector(
      `#calculo-do-gpa-d0221780070f5a957267`
    );

    // DOM Mutation Observer
    const observer = new MutationObserver(() => {
      this.form = formNode.querySelector("form");

      if (this.form) {
        this.form.onsubmit = (event) => {
          this.props.onChangePage();
        };
      }

      observer.disconnect();
    });

    const config = { attributes: true, childList: true, characterData: true };
    observer.observe(formNode, config);

    form.createForm();
  }

  render() {
    return <div role="main" id={"calculo-do-gpa-d0221780070f5a957267"} />;
  }
}

export default FormUser;
