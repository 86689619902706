import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Table, Button, Input, Card, Row, Col, AutoComplete } from "antd";
import React, { useState } from "react";
import { Select } from "antd";
import { CalculatorOutlined } from "@ant-design/icons";
import { useStore, TStore } from "../store";
import {
  Flexbox,
  TitleCard,
  Title,
  Paragraph,
  HistoryContent,
  Calc,
} from "./style";
import { subject } from "../../commons/subject";

const rowSelection = {
  getCheckboxProps: (record: { name: string }) => ({
    disabled: record.name === "initial",
    name: record.name,
  }),
};

const initial = {
  key: "initial",
  name: "initial",
  grade: "initial",
  hours: "initial",
};

const initInput = {
  name: "",
  grade: 0,
  hours: 0,
  nameText: undefined,
};

const makeRow = (
  counter: number,
  values: { name: any; grade: any; hours: any }
) => ({
  key: counter,
  name: values.name,
  grade: Number(values.grade),
  hours: Number(values.hours),
});

const History = ({ onChangePage }: { onChangePage: Function }) => {
  const { data, setDataSource }: TStore = useStore((state: TStore) => state);
  const [counter, setCounter] = useState(1);

  const [inputs, setInputs] = useState<any>(initInput);
  const onChangeValue = (key: string, value: string) => {
    setInputs({
      ...inputs,
      [key]: value,
    });
  };

  const onSelectValue = (key: string, value: string) => {
    setInputs({
      ...inputs,
      name: value,
      hours: subject.find((item) => item.subject === value)?.hours || 0,
    });
  };

  const handleAdd = () => {
    setDataSource([
      ...data,
      makeRow(counter, {
        name: inputs?.nameText || inputs.name,
        hours: inputs.hours,
        grade:
          typeof inputs.grade === "string"
            ? parseFloat(inputs.grade.replace(",", "."))
            : inputs.grade,
      }),
    ]);

    setCounter((prev) => prev + 1);
    setInputs(initInput);
  };

  const handleRemove = (key: any) => {
    setDataSource(data.filter((item: { key: any }) => item.key !== key));
  };

  const columns = [
    {
      title: "Matéria (opcional)",
      dataIndex: "name",
      render: (value: string) =>
        value === "initial" ? (
          <div>
            <Select
              onChange={(value: string) => {
                onSelectValue("name", value);
              }}
              style={{ width: "100%" }}
              options={subject.map((item) => ({
                label: item.subject,
                value: item.subject,
              }))}
            />

            {inputs.name === "OUTROS" && (
              <Input
                placeholder="Digite o nome da matéria"
                style={{ marginTop: 8 }}
                onChange={({ target }) => {
                  onChangeValue("nameText", target.value);
                }}
              />
            )}
          </div>
        ) : (
          value
        ),
    },
    {
      title: "Nota (0-10)",
      dataIndex: "grade",
      width: 150,
      render: (value: string) =>
        value === "initial" ? (
          <AutoComplete
            style={{ width: "100%" }}
            options={Array.from(Array(11).keys())
              .sort((a, b) => b - a)
              .map((item) => ({
                label: item,
                value: item,
              }))}
            onChange={(value: any) => {
              const newValue =
                typeof value === "string" ? value.replace(",", ".") : value;

              if (value === "") {
                onChangeValue("grade", value);
              }

              if (typeof newValue === "string") {
                if (parseFloat(newValue) >= 0 && parseFloat(newValue) <= 10) {
                  onChangeValue("grade", newValue.replace(".", ","));
                }
              } else {
                onChangeValue("grade", newValue);
              }
            }}
            value={inputs.grade}
          >
            <Input
              size="large"
              placeholder="Insira sua nota"
              value={inputs.grade}
            />
          </AutoComplete>
        ) : (
          value
        ),
    },
    {
      title: "",
      dataIndex: "action",
      width: "50px",
      render: (_: any, record: { name: string; key: any }) => (
        <>
          {record.name === "initial" && (
            <Button
              shape="circle"
              disabled={
                inputs.grade === 0 || inputs.hours === 0 || inputs.name === ""
              }
              onClick={handleAdd}
            >
              <PlusOutlined />
            </Button>
          )}
          {record.name !== "initial" && (
            <Button shape="circle" onClick={() => handleRemove(record.key)}>
              <DeleteOutlined />
            </Button>
          )}
        </>
      ),
    },
  ];

  const columnsReference = [
    {
      title: "Conceito-nota",
      dataIndex: "conceito",
      render: (value: string) => value,
    },
    {
      title: "Média Percentual",
      dataIndex: "media",
      render: (value: string) => value,
    },
    {
      title: "GPA",
      dataIndex: "gpa",
      render: (value: string) => value,
    },
    {
      title: "Nota",
      dataIndex: "nota",
      render: (value: string) => value,
    },
  ];

  const reference = [
    { conceito: "97-100", media: "100%", gpa: "4", nota: "A+" },
    { conceito: "92-96", media: "97%", gpa: "4", nota: "A" },
    { conceito: "90-92", media: "92%", gpa: "3,67", nota: "A-" },
    { conceito: "84-89", media: "90%", gpa: "3,33", nota: "B+" },
    { conceito: "70-76", media: "77%", gpa: "2,67", nota: "B-" },
    { conceito: "64-69", media: "70%", gpa: "2,33", nota: "C+" },
    { conceito: "56-63", media: "64%", gpa: "2", nota: "C" },
    { conceito: "50-55", media: "56%", gpa: "1,67", nota: "C-" },
    { conceito: "44-49", media: "50%", gpa: "1,33", nota: "D+" },
    { conceito: "37-43", media: "44%", gpa: "1", nota: "D" },
    { conceito: "30-36", media: "37%", gpa: "0,67", nota: "E" },
    { conceito: "Below 30", media: "30%", gpa: "0", nota: "F" },
  ];

  return (
    <HistoryContent>
      <Title>Calculadora de GPA</Title>

      <Paragraph>
        Para calcular automaticamente o Grade Point Average (GPA), adicione as
        matérias na tabela abaixo. A conversão acontecera automaticamente.
      </Paragraph>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Card>
            <Flexbox>
              <Table
                size="small"
                scroll={{ x: 350 }}
                rowSelection={rowSelection as any}
                columns={columns as any}
                dataSource={[initial, ...data]}
                pagination={false}
              />
            </Flexbox>
            <Calc>
              <Button
                disabled={data?.length < 1}
                type="primary"
                onClick={() => onChangePage()}
              >
                <CalculatorOutlined /> Calcular GPA
              </Button>
            </Calc>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card>
            <TitleCard>Tabela Auxiliar</TitleCard>
            <Table
              size="small"
              columns={columnsReference}
              dataSource={reference}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </HistoryContent>
  );
};

export { History };
