const calcHours = (data: any[]) =>
  data.reduce(
    (accumulator: any, currentValue: { hours: any }) =>
      accumulator + currentValue.hours,
    0
  );

const calcGrade = (data: any[]) =>
  data.reduce(
    (acumulador: number, currentValue: { grade: number; hours: number }) =>
      acumulador + currentValue.grade * currentValue.hours,
    0
  );

const calc = (data: any) => {
  const totalHours = calcHours(data);
  const totalGrade = calcGrade(data);
  const gpa = ((Math.round((totalGrade / totalHours) * 100) / 100) / 10) * 4 ;

  return {
    totalHours: calcHours(data),
    totalGrade: calcGrade(data),
    gpa,
  };
};

export { calc };
