import { Layout } from "antd";
import React, { memo } from "react";

import { Logo, Container, Title } from "./style";
import { IPropsHeader } from "./types";
import { mainTheme } from "../../theme/mainTheme";
import LogoDaquiPraFora from "../../assets/daquiprafora2024_PRIMARY_LOGO_.png";


const Header = memo(
  ({ title }: IPropsHeader): JSX.Element => (
    <Layout.Header
      style={{
        display: "flex",
        alignItems: "center",
        borderBottomWidth: 5,
        borderBottomColor: mainTheme.colors.primary.main,
        borderBottomStyle: "solid",
        height: 150,
        backgroundColor: '#F4F4F4'
      }}
    >
      <Container>
        <Logo>
          <img src={LogoDaquiPraFora} alt="Daqui pra fora" />
        </Logo>
        <Title>{title}</Title>
      </Container>
    </Layout.Header>
  )
);

Header.displayName = "Header";

export { Header };
