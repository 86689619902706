import { Header } from "../../components/header";
import { Calculator } from "../../components/calculator";

import { Layout } from "antd";
const { Content } = Layout;

export const Home = () => {
  return (
    
    <>
      <Header title={"Calculadora de GPA"} />
      <Content style={{ padding: '0px 16px'}}>
        <Calculator formActive />
      </Content>
    </>

  );
};
