
import { ThemeProvider, createGlobalStyle } from "styled-components";


import EffraTrialBoldSvg from './assets/fonts/EffraTrial-Bold.svg'
import EffraTrialRegularSvg from './assets/fonts/EffraTrial-Regular.svg'

import EffraTrialBoldWoff from './assets/fonts/EffraTrial-Bold.woff'
import EffraTrialRegularWoff from './assets/fonts/EffraTrial-Regular.woff'

import EffraTrialBoldEot from './assets/fonts/EffraTrial-Bold.eot'
import EffraTrialRegularEot from './assets/fonts/EffraTrial-Regular.eot'


import { mainTheme } from "./theme";
import { Routes } from "./routes/Routes";


const GlobalStyle = createGlobalStyle`  
    @font-face {
      font-family: 'Effra';
      src: local('Effra Trial'), url(${EffraTrialRegularEot});
      src: url('${EffraTrialRegularEot}?#iefix') format('embedded-opentype'),
          url(${EffraTrialRegularWoff}) format('woff'), 
          url(${EffraTrialRegularSvg}) format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Effra Trial Bold';
      src: url(${EffraTrialBoldEot});
      src: url('${EffraTrialBoldEot}?#iefix') format('embedded-opentype'),
          url(${EffraTrialBoldWoff}) format('woff'), 
          url(${EffraTrialBoldSvg}) format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }



  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${mainTheme.colors.grey["100"]};
    font-family: 'Effra' !important;
    color: ${mainTheme.colors.primary.text};
  }

  .ant-select-dropdown {
    min-width: 280px !important;
  }


  a {
    text-decoration: none;
  }

  .ant-layout-content {
    max-width: ${mainTheme.size.container.maxSize};
    width: 100%;
    margin: ${mainTheme.space(3)} auto;
  }
`;

export default function App() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={mainTheme}>
      
          <Routes />
        
      </ThemeProvider>
    </>
  );
}
