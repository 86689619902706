export const subject = [
  { subject: "CIÊNCIAS", hours: 160 },
  { subject: "FILOSOFIA", hours: 80 },
  { subject: "GEOGRAFIA", hours: 120 },
  { subject: "HISTÓRIA", hours: 120 },
  { subject: "INGLÊS", hours: 80 },
  { subject: "BIOLOGIA", hours: 160 },
  { subject: "FÍSICA", hours: 120 },
  { subject: "LINGUA PORTUGUESA / PORTUGUÊS", hours: 200 },
  { subject: "MATEMÁTICA", hours: 200 },
  { subject: "QUÍMICA", hours: 120 },
  { subject: "SOCIOLOGIA", hours: 80 },
  { subject: "OUTROS", hours: 80 },
];
