import styled from 'styled-components'
import Capa from "../../assets/capa.png";

const Logo = styled.div`
  max-width: 180px;
  height: 40px;
  margin-top: -10px;
  margin-bottom: 30px;
  img {
    width: 100%;
  }

  @media (max-width: 960px) {
    margin: auto auto 32px auto;
  }

  @media (max-width: 960px) {
    img {
      margin-left: -8px;
    }
  }
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.size.font.xxLarge};
  margin: 2px ${({ theme }) => theme.space(3)} 0px 0px;
  color: ${({ theme }) => theme.colors.primary.main};
  font-family: 'Effra Trial Bold' !important;

  @media (max-width: 960px) {
    font-size: ${({ theme }) => theme.size.font.xLarge};
    text-align: center;
  }

  @media (max-width: 420px) {
    font-size: ${({ theme }) => theme.size.font.medium};
  
  }
`

const Container = styled.div`
  max-width: ${({ theme }) => theme.size.container.maxSize};
  margin: auto;
  width: 100%;
  background-image: url(${Capa});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  height: 145px;

  @media (max-width: 960px) {
    background-image: none;
  }
  
`


export { Logo, Container, Title }
