import { styled } from "styled-components";
import { mainTheme } from "../../theme";

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  grid-gap: 16px;
  margin-bottom: 16px;

  button {
    border: 1px solid ${mainTheme.colors.primary.main};
    background-color: ${mainTheme.colors.primary.main};
    color: #fff;
    font-family: 'Effra Trial Bold' !important;
    padding: 9px 18px 9px 16px;
    font-size: 16px;
    height: 41px
  }

  @media (max-width: 440px) {
    display: grid;
  }
`;

export const Section = styled.section`
  max-width: 1280px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  margin: 32px auto;

  @media (max-width: 440px) {
    display: grid;
  }
`;

export const Underline = styled.span`
  text-decoration: underline;
`;

export const TitleCard = styled.h3`
  text-align: center;
  margin: auto auto 16px auto;
  width: 100%;
  color: ${mainTheme.colors.primary.main};
  font-family: 'Effra Trial Bold' !important;
`;

export const Title = styled.h1`
  text-align: center;
  margin: auto auto 16px auto;
  width: 100%;
  color: ${mainTheme.colors.primary.main};
  font-family: 'Effra Trial Bold' !important;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  grid-gap: 16px;
  font-family: 'Effra' !important;

  .ant-card {
    min-width: 180px;
  }

  margin-bottom: 16px;

  @media (max-width: 440px) {
    display: grid;
  }
`;

export const Result = styled.span`
  font-size: 32px;
  width: 100%;
  justify-content: center;
  display: flex;
  font-family: 'Effra Trial Bold' !important;
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.size.font.medium};
  font-family: 'Effra' !important;
`;



