const colors = {
  primary: {
    text: '#333',
    main: '#004dce',
  },
  secondary: {
    text: '#fff',
    main: '#3483fa',
  },
  grey: {
    100: '#EBEBEB',
    200: '#333',
    300: '#4E5459',
    400: 'rgba(0,0,0,.55)',
  },
  success: {
    main: '#00a650',
    text: '#00a650',
  },
  error: {
    main: '#CA5252',
    text: '#FF6E63',
  },
  info: {
    main: '#4A4D52',
    text: '#FFFFFF',
  },
}

const size = {
  font: {
    small: '14px',
    medium: '18px',
    large: '20px',
    xLarge: '26px',
    xxLarge: '36px',
  },
  container: {
    maxSize: '1280px',
    desktopWidth: 1280,
    tabletWidth: 860,
    mobileWidth: 720,
  },
}

const font = {
  family: 'Effra Trial',
}

const space = (size: number, sizeHorizontal?: number) =>
  `${8 * size}px ${sizeHorizontal ? `${8 * sizeHorizontal}px` : ''}`

const mainTheme = { colors, size, font, space }

export { mainTheme }
