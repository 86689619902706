import React, { useState } from "react";

import  FormUser from "../form-user";
import { History } from "../history";
import { Resume } from "../resume";

const Calculator = ({ formActive }: { formActive?: boolean }) => {
  const [page, setPage] = useState(0);
  return (
    <>
      {page === 0 && (
        <History onChangePage={() => setPage(formActive ? 1 : 2)} />
      )}
      {page === 1 && formActive && <FormUser onChangePage={() => setPage(2)} />}
      {page === 2 && <Resume infos={!formActive} onChangePage={() => setPage(0)} />}
    </>
  );
};

export { Calculator };
